import { employeeinfo } from "../commonDynmicForms/employee_info";
import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";

export default {
    fillable_by:FillableBy.Both,
    title:
        "Clinical Competency Checklist for Mr. Frosty Heater-Cooler Device",
    sections: [
        // {...employeeinfo},
        {
            title: "Standard Use",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Verifies glycol level is at 2 or 3 bars when removed from the Igloo.",
                "Performs priming for both Systemic and Cardioplegia (CPG) lines.",
                "Tests heating and cooling functions pre-case to ensure proper circulation and goal temperature achievement.",
                "Operates the device according to clinical protocol during the case.",
                "Properly de-primes the tanks and disconnects Heat Transfer Fluid (HTF) hoses post-case.",
                "Returns the device to the Igloo for recharging after use."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Hot Swap Procedure",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Removes backup Frosty from the Igloo and detaches charging hoses.",
                "Primes both tanks on the backup Frosty and matches goal temperatures.",
                "Switches HTF lines from the depleted Frosty to the new Frosty and restarts operation.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Troubleshooting",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Performs a reboot when issues occur during boot-up.",
                "Resolves circulating icon issues during priming by checking connections and shaking HTF lines.",
                "Adjusts temperature set points if the device fails to reach the goal temperature.",
                "Executes a Frosty swap if troubleshooting steps fail.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Cleaning and Maintenance",
            type: "table",
            heads:constants.tableHeads,
            rows: [
                "Disconnects all hoses before cleaning and disinfection.",
                "Cleans surfaces with pre-soaked disinfectant wipes or 70% isopropyl alcohol.",
                "Inspects for external damage and ensures thorough cleaning of couplings, connections, and hoses.",
                "Logs all cleaning and maintenance activities in the appropriate records.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Igloo Use and Maintenance",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Maintains Igloo glycol levels at 75% of maximum capacity.",
                "Ensures Frosties are balanced to 2 or 3 bars while charging on the Igloo.",
                "Troubleshoots Igloo issues, including overfilled or underfilled glycol levels.",
                "Manages frozen charge hoses safely and effectively.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Emergency Procedures",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Demonstrates knowledge of emergency shutdown procedures.",
                "Identifies and addresses yellow wrench alerts and hardware faults appropriately.",
                "Contacts Spectrum Medical for assistance with irrecoverable alerts or severe faults.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
         {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields: getSignatures(FillableBy.Both)
        },
    ],
}